import gql from 'graphql-tag';

const CREATE_ORDER = gql`
  mutation createAdOrder(
    $date: Time
    $prepaid: Boolean
    $pricepermonth: Int
    $months: Int
    $org: ID
    $ads: [AdInput]
    $type: ID
  ) {
    createAdOrder(
      data: {
        date: $date
        prepaid: $prepaid
        pricepermonth: $pricepermonth
        months: $months
        type: { connect: $type }
        org: { connect: $org }
        ads: { create: $ads }
      }
    ) {
      _id
      slug
      date
      months
      pricepermonth
      prepaid
      type {
        name
        retail_price
        discount_price
        width
        height
        notes
        custom
      }
      org {
        name
        discount
      }
      ads {
        data {
          _id
          placed
          archived
          paid
          type {
            name
            retail_price
            discount_price
            width
            height
            notes
            custom
          }
          org {
            name
            user {
              email
            }
          }
        }
      }
    }
  }
`;

export default CREATE_ORDER;
