import React from 'react';
import './ad-type.css';

const AdType = ({ adtype }) => {
  const {
    _id,
    slug,
    name,
    retail_price,
    discount_price,
    height,
    width,
    notes,
  } = adtype;
  const image = `/images/${slug}.png`;

  return (
    <div key={_id} className="adtype">
      <img src={image} alt={name} />
      <h4>{name}</h4>
      <p>
        {height}” tall x {width}” wide
      </p>
      <p className="ad-price">${discount_price}</p>
      <p className="ad-price">${retail_price}</p>
      {notes ? <p className="adtype-note">{notes}</p> : ''}
    </div>
  );
};

export default AdType;
