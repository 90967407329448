import dedent from 'dedent';
import sendEmail from '../_functions/send-mail';

const sendNewOrderNotification = (orgName, order, type) => {
  const { name: typename } = type;
  const { months, pricepermonth } = order;

  let summary;
  if (months > 1)
    summary = `${months} ${typename} ads at $${pricepermonth}/mo after discounts applied, for a total of $${
      months * pricepermonth
    }.`;
  else summary = `${months} ad at $${pricepermonth} total`;

  const text = dedent`
  Order details:

  ${summary}
  `;

  sendEmail({
    from: 'Tumbleweird Adstravaganza <adstravaganza@tumbleweird.org>',
    to: 'adamavenir@hey.com',
    subject: `✨ New order for ${orgName} 🎉`,
    text,
  });
};

export default sendNewOrderNotification;
