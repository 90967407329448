import gql from 'graphql-tag';
import { USER_SHALLOW } from '../user';

const UPDATE_USER = gql`
  mutation(
    $id: ID!
    $name: String!
    $email: String!
    $slug: String!
    $onboarded: Boolean!
  ) {
    updateUser(
      id: $id
      data: { name: $name, email: $email, slug: $slug, onboarded: $onboarded }
    ) {
      ...UserShallow
    }
  }
  ${USER_SHALLOW}
`;

export default UPDATE_USER;
