import React from 'react';
import { useQuery } from 'urql';
import DefaultLayout from '../_layouts/default-layout';
import ALL_ADTYPES from './all-ad-types-query';
import { LoadingPage } from '../_views';
import AdType from './ad-type';

const AdTypes = ({ account }) => {
  // eslint-disable-next-line no-unused-vars
  const [result] = useQuery({
    query: ALL_ADTYPES,
  });

  const { data, err } = result;
  if (err) return err.message;

  // what broke `account` being immediately available here?
  if (account) {
    const { org } = account;

    return (
      <DefaultLayout>
        <h1>Ad types</h1>
        <p>
          <a href="/rates/create">Create ad type »</a>
        </p>
        <div className="types">
          {data
            ? data.allAdTypes.data.map(adtype => (
              <AdType
                  key={adtype._id}
                  adtype={adtype}
                  discount={org.discount}
                />
              ))
            : ''}
        </div>
      </DefaultLayout>
    );
  }

  return (
    <div className="types">
      <LoadingPage />
    </div>
  );
};

export default AdTypes;
