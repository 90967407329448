import dedent from 'dedent';
import sendEmail from '../_functions/send-mail';

const sendWelcomeEmail = (
  userEmail,
  orgEmail,
  orgName,
  order,
  type,
  firstAd
) => {
  const { height, width, notes } = type;
  const { months, pricepermonth } = order;

  const recipients = `${userEmail}, ${orgEmail}`;

  const magicLink = `https://app.tumbleweird.org/ads/${firstAd}`;

  let summary;
  if (months > 1)
    summary = `${months} ads at $${pricepermonth}/mo after discounts applied, for a total of $${
      months * pricepermonth
    }.`;
  else summary = `${months} ad at $${pricepermonth} total`;

  const text = dedent`
  Thank you for advertising in Tumbleweird! 

  Here’s some info about your order for your records.

  ${summary}

  Your ad is ${width}” wide x ${height}” tall. ${notes}

  Oh—and here’s a Pro Tip™! You can share the ✨ magic link ✨ for each month's ad with your designer so they can upload your ad without ever needing to log in. 
  
  Here’s the magic link for your first month’s ad: ${magicLink}

  By default, ads are scheduled to run the next chance they can be. If you want to space your ads out or schedule them for a specific time, just reply to this email and let us know.

  As always, don’t hesitate to reach out any time with questions or feedback.

  —Adam and the Tumbleweird team
  `;

  sendEmail({
    from: 'Tumbleweird Adstravaganza <adstravaganza@tumbleweird.org>',
    to: recipients,
    subject: `✅ Confirming ${orgName}’s Tumbleweird ad order`,
    text,
  });
};

export default sendWelcomeEmail;
