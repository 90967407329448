import React from 'react';
import { useQuery } from 'urql';
import ALL_ORGS from './all-orgs-query';

const AllOrgs = () => {
  // eslint-disable-next-line no-unused-vars
  const [result, reexecuteQuery] = useQuery({
    query: ALL_ORGS,
  });

  const { data, err } = result;
  if (err) return err.message;

  return (
    <div>
      <pre>
        <code>{JSON.stringify(data, undefined, 2)}</code>
      </pre>
    </div>
  );
};

export default AllOrgs;
