import React from 'react';
import AdList from '../ad/ad-list';
import './order.css';

const OrderListItem = props => {
  const { order, showprice } = props;
  // const { order } = props;
  let orderdate;
  if (order.date) {
    orderdate = new Date(order.date);
    orderdate = orderdate.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  }
  return (
    <div key={order._id} className="order-group">
      <h2>{order.type.name}</h2>
      <p>
        Ordered {orderdate} — {order.type.width}” wide x {order.type.height}” tall {order.type.notes ? `(${order.type.notes})` : ''}
      </p>
      {showprice === 'true' ? (
        <div className="order-price-info">
          <p>
            ${order.pricepermonth} per month, $
            {order.pricepermonth * order.months} total —{' '}
            {/* {order.paid ? <span>Paid</span> : <span>Payable</span>} */}
          </p>
        </div>
      ) : ''
      }
      <AdList ads={order.ads.data} />
    </div>
  );
};

export default OrderListItem;
