import React from 'react';
import { useQuery } from 'urql';
import DefaultLayout from '../_layouts/default-layout';
import ALL_ADS from './all-ads-query';
import AdListItem from './ad-list-item';
import { LoadingPage } from '../_views';

const AllAds = props => {
  const { history } = props;

  const [result] = useQuery({
    query: ALL_ADS,
  });

  const { data, err, fetching } = result;
  if (err) return err.message;
  if (fetching) return <LoadingPage />;
  if (data) {
    return (
      <DefaultLayout>
        {data.allAds.data.map(ad => {
          return <AdListItem key={ad._id} ad={ad} />;
        })}
      </DefaultLayout>
    );
  }
  return history.push('/500');
};

export default AllAds;
