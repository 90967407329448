import gql from 'graphql-tag';
import { ORG_SHALLOW } from '../org';
import ADTYPE_SHALLOW from './ad-type-shallow-fragment';

const CREATE_ADTYPE = gql`
  mutation(
    $slug: String!
    $name: String!
    $retail_price: Int!
    $discount_price: Int!
    $width: String!
    $height: String!
    $notes: String
  ) {
    createAdType(
      data: {
        slug: $slug
        name: $name
        retail_price: $retail_price
        discount_price: $discount_price
        width: $width
        height: $height
        notes: $notes
      }
    ) {
      ...AdTypeShallow
      org {
        ...OrgShallow
      }
    }
  }
  ${ADTYPE_SHALLOW}
  ${ORG_SHALLOW}
`;

export default CREATE_ADTYPE;
