import React from 'react';
import { Link } from 'react-router-dom';

const OrderProcessInfo = () => {
  return (
    <div className="welcome order-process-info">
      <h2>Here’s how the process works:</h2>
      <ol>
        <li>
          <strong>Order an ad.</strong> You can play with rates and discounts.
          We give multi-month discounts for 3+ months and an extra 10% off for 
          prepayment on orders of 3+ months.
        </li>
        <li>
          <strong>Upload your ad later.</strong> We’ll even give you a{' '}
          <span role="img" aria-label="magic">
            ✨
          </span>{' '}
          magic link{' '}
          <span role="img" aria-label="magic">
            ✨
          </span>{' '}
          you can send your designer so they can upload it for you.
        </li>
        <li>
          <strong>We’ll invoice you.</strong>
        </li>
      </ol>
      <p>Ready, boots? Start orderin’.</p>
      <Link to='/order' className="button">
        <button type="button">
          <span role="img" aria-label="dancing men">
            👯‍♂️
          </span>
          &nbsp; Check Rates and Order an Ad
        </button>
      </Link>
    </div>
  );
};

export default OrderProcessInfo;
