import React from 'react';
import LoginButton from './login-button';
import Logo from './logo';
import './nav.css';

const NavPublic = () => {
  return (
    <nav>
      <Logo />
      <LoginButton />
    </nav>
  );
};

export default NavPublic;
