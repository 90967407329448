import React from 'react';

const FourOhFour = () => {
  const style = {
    textAlign: 'center',
    margin: '100px',
    color: 'red',
    fontSize: '150px',
  };
  return <h1 style={style}>404</h1>;
}

export default FourOhFour;
