import sendEmail from '../_functions/send-mail';

const sendNewSignupNotification = state => {
  const {
    userName,
    userEmail,
    orgName,
    orgType,
    orgDiscount,
    orgEmail,
  } = state;

  sendEmail({
    from: 'Tumbleweird Ad Exec <adexec@tumbleweird.org>',
    to: 'adamavenir@hey.com',
    subject: `✨ New signup: ${userName} (${orgName})`,
    text: `Woo! 🎉\n\nType: ${orgType}\nDiscount? ${orgDiscount}\nInvoices: ${orgEmail}\n\nYou could email them about this:\n\nReach them at: ${userEmail}.`,
  });
};

export default sendNewSignupNotification;
