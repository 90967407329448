import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAuth0 } from '@auth0/auth0-react';
import { NavUser, NavPublic } from '../nav';

export default props => {
  const { pageTitle, bodyClass, children } = props;
  const { isAuthenticated } = useAuth0();
  return (
    <HelmetProvider>
      <Helmet>
        <meta charset="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="chrome-1" />
        <meta name="description" content="Tumbleweird ad manager" />
        <meta name="keywords" content="" />
        <meta name="author" content="Tumbleweird, SPC" />
        <title>
          {pageTitle ? `${pageTitle} — ` : ''}
          Tumbleweird Adstravaganza
        </title>
        <link rel="shortcut icon" href="" />
        <body className={bodyClass} />
      </Helmet>
      <header>{isAuthenticated ? <NavUser /> : <NavPublic />}</header>
      <main>{children}</main>
    </HelmetProvider>
  );
};
