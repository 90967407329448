import dedent from 'dedent';
import sendEmail from '../_functions/send-mail';
// import superagent from 'superagent';

const sendAdUpdateEmail = async ad => {
  const { org, rundate, image } = ad;
  const { name, user } = org;

  let date = new Date(rundate);
  date = date.toLocaleString('en-US', {
    month: 'long',
    year: 'numeric',
  });

  const emailText = dedent`
  Howdy!

  Someone just used a ✨ magic link ✨ to upload your Tumbleweird ad for ${name}'s ${date} ad.

  Oh, look! Here it is: ${image}

  As always, don’t hesitate to reach out any time with questions or feedback.

  —Adam and the Tumbleweird team
  `;

  const emailHtml = `<p>Howdy!</p><p>Someone just used a ✨ magic link ✨ to upload your Tumbleweird ad for ${name}'s ${date} ad.</p><p>Look! Here it is:</p><a href="${image}"><img src="${image}" /></a><p>As always, don’t hesitate to reach out any time with questions or feedback.</p><p>—Adam and the Tumbleweird team</p>`;

  sendEmail({
    from: 'Tumbleweird Adstravaganza <adstravaganza@tumbleweird.org>',
    to: user.email,
    subject: `${name} ${date} ad uploaded`,
    text: emailText,
    html: emailHtml,
  });
};

export default sendAdUpdateEmail;
