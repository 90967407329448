import gql from 'graphql-tag';

const ORDER_SHALLOW = gql`
  fragment OrderShallow on AdOrder {
    _id
    slug
    date
    org {
      _id
      name
      discount
    }
    type {
      name
      slug
    }
    months
    pricepermonth
    prepaid
    archived
    invoiced
    canceled
    paid
  }
`;

export default ORDER_SHALLOW;
