import gql from 'graphql-tag';
import ORDER_SHALLOW from './order-shallow-fragment';
import AD_SHALLOW from '../ad/ad-shallow-fragment';
import ADTYPE_SHALLOW from '../ad-type/ad-type-shallow-fragment';
import ORG_SHALLOW from '../org/org-shallow-fragment';

const ORDER_DEEP = gql`
  fragment OrderDeep on AdOrder {
    ...OrderShallow
    ads {
      data {
        ...AdShallow
      }
    }
    org {
      ...OrgShallow
    }
    type {
      ...AdTypeShallow
    }
  }
  ${ORDER_SHALLOW}
  ${ADTYPE_SHALLOW}
  ${AD_SHALLOW}
  ${ORG_SHALLOW}
`;

export default ORDER_DEEP;
