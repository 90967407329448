import gql from 'graphql-tag';

const USER_SHALLOW = gql`
  fragment UserShallow on User {
    _id
    admin
    name
    email
    user_id
    picture
    notes
    onboarded
  }
`;

export default USER_SHALLOW;
