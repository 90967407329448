import dedent from 'dedent';
import sendEmail from '../_functions/send-mail';

const sendAccountUpdateEmail = state => {
  const { userEmail } = state;

  const text = dedent`
  Hey!

  Just a note to confirm that your account information was successfully updated.

  If this wasn’t you, let us know.

  —Adstravaganza
  `;

  sendEmail({
    from: 'Tumbleweird Adstravaganza <adstravaganza@tumbleweird.org>',
    to: userEmail,
    subject: `📝 Account successfully updated`,
    text,
  });
};

export default sendAccountUpdateEmail;
