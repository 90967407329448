import gql from 'graphql-tag';
import USER_DEEP from '../user/user-deep-fragment';

const SIGNUP = gql`
  mutation(
    $id: ID!
    $name: String!
    $email: String!
    $slug: String!
    $onboarded: Boolean!
    $org: OrgInput
  ) {
    updateUser(
      id: $id
      data: {
        name: $name
        email: $email
        slug: $slug
        onboarded: $onboarded
        org: { create: $org }
      }
    ) {
      ...UserDeep
    }
  }
  ${USER_DEEP}
`;

export default SIGNUP;
