import gql from 'graphql-tag';

const ORG_SHALLOW = gql`
  fragment OrgShallow on Org {
    _id
    slug
    name
    type
    discount
    email
  }
`;

export default ORG_SHALLOW;
