import React from 'react';
import './ad-rate.css';

const AdRate = ({ adtype }) => {
  const { _id, slug, name, height, width, notes } = adtype;
  const image = `/images/${slug}.png`;

  return (
    <div key={_id} className="rate">
      <div className="rate-wrap">
        <img
          src={image}
          alt={name}
          title={`${height}” x ${width}” (${notes})`}
        />
      </div>
      <h4>{name}</h4>
    </div>
  );
};

export default AdRate;
