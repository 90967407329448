import dedent from 'dedent';
import sendEmail from '../_functions/send-mail';

const sendAdUpdateNotification = async ad => {
  const { org, rundate, image } = ad;
  const { name } = org;

  let date = new Date(rundate);
  date = date.toLocaleString('en-US', {
    month: 'long',
    year: 'numeric',
  });

  const emailText = dedent`
  Here’s ${name}’s Tumbleweird ad for ${date}.
  
  ${image}
  `;

  const emailHtml = `Here’s ${name}’s Tumbleweird ad for ${date}.</p><a href="${image}"><img src="${image}" /></a>`;

  sendEmail({
    from: 'Tumbleweird Adstravaganza <adstravaganza@tumbleweird.org>',
    to: 'adam@tumbleweird.org',
    subject: `${name} ${date} ad uploaded`,
    text: emailText,
    html: emailHtml,
  });
};

export default sendAdUpdateNotification;
