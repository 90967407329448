import gql from 'graphql-tag';
import USER_SHALLOW from './user-shallow-fragment';
import ORG_SHALLOW from '../org/org-shallow-fragment';

// does this need to be nested in data { }?
const USER_DEEP = gql`
  fragment UserDeep on User {
    ...UserShallow
    org {
      ...OrgShallow
    }
  }
  ${USER_SHALLOW}
  ${ORG_SHALLOW}
`;

export default USER_DEEP;
