import gql from 'graphql-tag';
import ADTYPE_SHALLOW from './ad-type-shallow-fragment';

const ALL_ADTYPES = gql`
  query {
    allAdTypes {
      data {
        ...AdTypeShallow
      }
    }
  }
  ${ADTYPE_SHALLOW}
`;

export default ALL_ADTYPES;
