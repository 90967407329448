import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Provider as GraphQLProvider } from 'urql';
import gq from '../../_config/urql';
import SignupLayout from '../_layouts/signup-layout';
import AppRouter from './app-router';
import './app.css';

function App() {
  const { isLoading, error } = useAuth0();

  if (isLoading) return <SignupLayout />;

  return (
    <GraphQLProvider value={gq}>
      <AppRouter />
      {error ? <pre>{error.message}</pre> : ''}
    </GraphQLProvider>
  );
}

export default App;
