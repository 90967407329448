import React from 'react';
import OrderListItem from './order-list-item';

const OrderList = props => {
  const { orders, showprice } = props;

  return (
    <>
      {orders.data.map(order => {
        return (
          <OrderListItem key={order._id} order={order} showprice={showprice} />
        );
      })}
    </>
  );
};

export default OrderList;
