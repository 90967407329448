import gql from 'graphql-tag';
import AD_SHALLOW from './ad-shallow-fragment';
import ORDER_SHALLOW from '../order/order-shallow-fragment';

const AD_DEEP = gql`
  fragment AdDeep on Ad {
    ...AdShallow
    order {
      ...OrderShallow
      type {
        _id
        name
        width
        height
        notes
        custom
      }
      order
    }
    org {
      name
      user {
        email
      }
    }
  }
  ${AD_SHALLOW}
  ${ORDER_SHALLOW}
`;

export default AD_DEEP;
