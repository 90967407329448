import React from 'react';
// import { Link } from 'react-router-dom';
import './ad.css';
import AdListItem from './ad-list-item';

const AdList = props => {
  const { ads } = props;

  // split dates into two groups:
  // future issues (active)
  // past issues (archived)
  const future = [];
  const past = [];

  const today = new Date();

  // get future issues by:
  // - getting current date
  // - getting rundate
  // - if currentdate before rundate - future
  // - if currentdate after rundate - past  

  ads.forEach(ad => {
    const rundate = new Date(ad.rundate);
    if (rundate >= today) future.push(ad);
    if (rundate <= today) past.push(ad);
  })

  return (
    <div className="purchases">
      <div className="future">
        {future.map(ad => {
          return <AdListItem key={ad._id} ad={ad} />;
        })}
      </div>
      <div className="past">
        {past.map(ad => {
          return <AdListItem key={ad._id} ad={ad} />;
        })}
      </div>
    </div>
  );
};

export default AdList;

