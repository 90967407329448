import { createClient } from 'urql';

const gq = createClient({
  url: 'https://graphql.fauna.com/graphql',
  fetchOptions: () => {
    const token = process.env.REACT_APP_FAUNADB_KEY;
    return {
      headers: { authorization: token ? `Bearer ${token}` : '' },
    };
  },
});

export default gq;
