import React from 'react';
import { useQuery } from 'urql';
import { useAuth0 } from '@auth0/auth0-react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import history from '../../_utils/history';
import SignupLayout from '../_layouts/signup-layout';
import { USER_BY_USERID } from '../user';
import { Signup } from '../signup';
import { CreateOrder } from '../order-create';
import { ViewOrder, ListOrders } from '../order';
import { ViewAd, ListAds, OrgAds } from '../ad';
import { AccountEdit } from '../account';
import { AdTypes, CreateAdType } from '../ad-type';
import { FiveHundred, FourOhFour } from '../_views';
import { Landing, UserDashboard, AdminDashboard, Privacy } from '../dashboard';

const AppRouter = () => {
  const { error: authErr, user } = useAuth0();

  let onboarded = false;
  let admin = false;

  const [result] = useQuery({
    query: USER_BY_USERID,
    variables: { user_id: user.sub },
    pause: !user,
  });

  const { data, fetching, error: dbErr } = result;
  while (fetching) return <SignupLayout />;
  if (dbErr || authErr) console.error(dbErr, authErr);

  if (user && data) {
    onboarded = data.userByUserId.onboarded;
    admin = data.userByUserId.admin;
    // console.log(admin)
    if (data && !onboarded) history.push('/signup');
    const account = data.userByUserId;

    // TODO: can this get a little tighter? / abstract out the weirdness?
    return (
      <Router>
        <Switch>
          {admin ? (
            <>
              <Route
                path="/"
                exact
                render={props => (
                  <AdminDashboard {...props} account={account} />
                )}
              />
              <Route path="/privacy" exact component={Privacy} />
              <Route
                path="/rates"
                exact
                render={props => <AdTypes {...props} account={account} />}
              />
              <Route
                path="/rates/create"
                exact
                render={props => <CreateAdType {...props} account={account} />}
              />
              <Route
                path="/orders"
                exact
                render={props => <ListOrders {...props} account={account} />}
              />
              <Route
                path="/ads"
                exact
                render={props => <ListAds {...props} account={account} />}
              />
              <Route
                path="/ads/:adid"
                exact
                render={props => <ViewAd {...props} account={account} />}
              />
            </>
          ) : (
            ''
          )}
          {onboarded && !admin ? (
            <>
              <Route
                path="/account"
                exact
                render={props => <AccountEdit {...props} account={account} />}
              />
              <Route path="/privacy" exact component={Privacy} />
              <Route
                path="/"
                exact
                render={props => <UserDashboard {...props} account={account} />}
              />
              <Route
                path="/order"
                exact
                render={props => <CreateOrder {...props} account={account} />}
              />
              <Route
                path="/order/:orderid"
                exact
                render={props => <ViewOrder {...props} account={account} />}
              />
              <Route
                path="/ads"
                exact
                render={props => <OrgAds {...props} account={account} />}
              />
              <Route
                path="/ads/:adid"
                exact
                render={props => <ViewAd {...props} account={account} />}
              />
            </>
          ) : (
            <>
              <Route
                path="/signup"
                render={props => <Signup {...props} account={account} />}
              />
            </>
          )}
          <Route path="/privacy" exact component={Privacy} />
          <Route path="/500" exact component={FiveHundred} />
          <Route path="/404" exact component={FourOhFour} />
          {/* TODO: make this 404 work correctly, rather than showing when redirecting from signup lol */}
          {/* <Route component={FourOhFour} /> */}
        </Switch>
      </Router>
    );
  }
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/privacy" exact component={Privacy} />
        <Route
          path="/ads/:adid"
          exact
          render={props => <ViewAd {...props} />}
        />
        <Route path="/500" exact component={FiveHundred} />
        <Route path="/404" exact component={FourOhFour} />
        {/* <Route component={FourOhFour} /> */}
      </Switch>
    </Router>
  );
};

export default AppRouter;
