import gql from 'graphql-tag';
import AD_SHALLOW from './ad-shallow-fragment';

const ALL_ADS = gql`
  query {
    allAds {
      data {
        ...AdShallow
      }
    }
  }
  ${AD_SHALLOW}
`;

export default ALL_ADS;
