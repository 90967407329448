import gql from 'graphql-tag';
import AD_DEEP from './ad-deep-fragment';

const AD_BY_ID = gql`
  query($id: ID!) {
    findAdByID(id: $id) {
      ...AdDeep
    }
  }
  ${AD_DEEP}
`;

export default AD_BY_ID;
