import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'urql';
import request from 'superagent';
import ADD_IMAGES from './add-images-mutation';
import Loading from '../_elements/loading';

const onUploadProgress = (fileName, progress) => {
  console.log(progress);
};

const Upload = props => {
  const { adid, ad, history } = props;
  const { image } = ad;

  function handleImageAdded(imageSet) {
    props.onChange(imageSet);
  }

  const cloudinary = {
    cloudName: 'tumbleweird',
    uploadPreset: 'vopnbaum',
  };

  // eslint-disable-next-line no-unused-vars
  const [adState, updateAd] = useMutation(ADD_IMAGES);
  let imageSet = {};

  const onUploaded = data => {
    updateAd(data).then(result => {
      // console.log(result);
      if (result.error) return console.error(result.error);
      if (result.error) return history.push('/500');
      return handleImageAdded(imageSet);
    });
  };

  const onDrop = useCallback(
    acceptedFiles => {
      const url = `https://api.cloudinary.com/v1_1/${cloudinary.cloudName}/upload`;

      const file = acceptedFiles[0];
      request
        .post(url)
        .field('upload_preset', cloudinary.uploadPreset)
        .field('file', file)
        // .field('multiple', false) // TODO is this necessary if false?
        .on('progress', progress => onUploadProgress(file.name, progress)) // TODO: add progress bar
        .end((error, response) => {
          const { body: img } = response;
          const { secure_url: fullsize, public_id: publicId, format } = img;
          const baseUrl = `https://res.cloudinary.com/tumbleweird/image/upload`;

          // eslint-disable-next-line react-hooks/exhaustive-deps
          imageSet = {
            id: adid,
            ad: {
              image: fullsize,
              thumb: `${baseUrl}/w_200/${publicId}.${format}`,
              tiny: `${baseUrl}/w_80/${publicId}.${format}`,
            },
          };
          acceptedFiles.shift();
          return onUploaded(imageSet);
        });
    },
    [imageSet]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const files = acceptedFiles.map(file => (
    <p key={file.size} className="uploaded-file-description">
      <Loading />
      Uploading {file.path} ({file.size} bytes)
    </p>
  ));

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <p>Click or drag a file here to upload</p>
        {files ? <aside>{files}</aside> : ''}
        {image ? <img src={image} alt="ad" /> : ''}
        <input {...getInputProps()} />
      </div>
    </section>
  );
};

export default Upload;
