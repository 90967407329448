import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useMutation } from 'urql';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import sendAccountUpdateEmail from '../../_email/send-account-update-email';
import SignupLayout from '../_layouts/signup-layout';
import UPDATE_ACCOUNT from './update-account-mutation';

const AccountUpdateForm = props => {
  const { account, history } = props;
  const {
    name: origUserName,
    email: origUserEmail,
    _id: userRef,
    org,
  } = account;
  const { name: origOrgName, email: origOrgEmail, _id: orgRef } = org;

  const [state, setState] = useState({
    userEmail: origUserEmail,
    userName: origUserName,
    orgName: origOrgName,
    orgEmail: origOrgEmail,
  });

  const [accountState, updateAccount] = useMutation(UPDATE_ACCOUNT);

  const handleChange = e => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setState({
      ...state,
      [target.name]: value,
    });
  };

  const variables = {
    userId: userRef,
    user: {
      name: state.userName,
      email: state.userEmail,
      slug: _.kebabCase(state.userName),
    },
    orgId: orgRef,
    org: {
      name: state.orgName,
      slug: _.kebabCase(state.orgName),
      email: state.orgEmail,
    },
  };

  const handleSubmit = () => {
    updateAccount(variables).then(result => {
      if (result.error) return history.push('/500');
      sendAccountUpdateEmail(state);
      return toast.success(`✨ Account updated`);
    });
  };

  return (
    <SignupLayout>
      <ToastContainer />
      <form className="account-form">
        <label htmlFor="userName">
          Name
          <input
            type="text"
            name="userName"
            value={state.userName}
            onChange={handleChange}
          />
        </label>
        <label htmlFor="userEmail">
          Email
          <input
            type="text"
            name="userEmail"
            value={state.userEmail}
            onChange={handleChange}
          />
        </label>
        <label htmlFor="orgName">
          Name of company / organization
          <input
            type="text"
            name="orgName"
            value={state.orgName}
            onChange={handleChange}
          />
        </label>
        <label htmlFor="orgEmail">
          What email should we send invoices to?
          <input
            type="text"
            name="orgEmail"
            value={state.orgEmail}
            onChange={handleChange}
          />
        </label>
        <input type="hidden" name="userRef" value={userRef} />
        <button
          type="button"
          disabled={accountState.fetching}
          onClick={handleSubmit}
        >
          Save changes
        </button>
      </form>
    </SignupLayout>
  );
};

export default withAuthenticationRequired(withRouter(AccountUpdateForm));
