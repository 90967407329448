import gql from 'graphql-tag';
import ORDER_DEEP from './order-deep-fragment';

const ORDER_BY_ID = gql`
  query($id: ID!) {
    findAdOrderByID(id: $id) {
      ...OrderDeep
    }
  }
  ${ORDER_DEEP}
`;

export default ORDER_BY_ID;
