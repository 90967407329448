import gql from 'graphql-tag';
import USER_DEEP from './user-deep-fragment';

const USER_BY_USERID = gql`
  query($user_id: String!) {
    userByUserId(user_id: $user_id) {
      ...UserDeep
    }
  }
  ${USER_DEEP}
`;

export default USER_BY_USERID;
