import React from 'react';
import DefaultLayout from '../_layouts/default-layout';
import Loading from '../_elements/loading';

const LoadingPage = () => {
  return (
    <DefaultLayout pageTitle="Loading...">
      <div className="loading-container">
        <Loading />
      </div>
    </DefaultLayout>
  );
};

export default LoadingPage;
