import gql from 'graphql-tag';
import USER_DEEP from './user-deep-fragment';

const ALL_USERS = gql`
  query {
    allUsers {
      data {
        ...UserDeep
      }
    }
  }
  ${USER_DEEP}
`;

export default ALL_USERS;
