import React from 'react';
import DefaultLayout from '../_layouts/default-layout';
import AccountUpdateForm from './account-update-form';
import LogoutButton from '../nav/logout-button';

const Account = ({ account }) => {
  return (
    <DefaultLayout pageTitle="Edit account details">
      <h1>Edit account details</h1>
      <AccountUpdateForm account={account} />
      <LogoutButton />
    </DefaultLayout>
  );
};

export default Account;
