import React from 'react';
import SignupForm from './signup-form';
import SignupLayout from '../_layouts/signup-layout';

const Signup = ({ account }) => {
  return (
    <SignupLayout pageTitle="Sign up">
      <div id="onboarding">
        <h1>Create your account</h1>
        <SignupForm account={account} />;
      </div>
    </SignupLayout>
  );
};

export default Signup;
