import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useMutation } from 'urql';
import _ from 'lodash';
import SignupLayout from '../_layouts/signup-layout';
import CREATE_ADTYPE from './create-ad-type-mutation';
// import OrgOptions from '../org/org-options';

// TODO make conditional based on 'custom' state, prepopulate custom field
const CreateAdTypeForm = props => {
  // let ref;

  const [name, setName] = useState('');
  const [retail_price, setRetailPrice] = useState('');
  const [discount_price, setDiscountPrice] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [notes, setNotes] = useState('');
  // const [custom, setCustom] = useState(false);
  // const [org, setOrg] = useState('');
  const slug = _.kebabCase(name);
  // if (org) ref = Number.parseInt(org, 10);

  const [adTypeState, createAdType] = useMutation(CREATE_ADTYPE);

  const submit = () => {
    const variables = {
      name,
      slug,
      retail_price: Number.parseInt(retail_price, 10),
      discount_price: Number.parseInt(discount_price, 10),
      width,
      height,
      notes,
      custom: false,
      // org: ref,
    };

    createAdType(variables).then(result => {
      console.log('result', result);
      if (result.error) throw result.error.message;
      if (result.data) props.history.push('/rates');
    });

    // if (custom) {
    //   sendEmail({
    //     from: 'Tumbleweird Ad Exec <adexec@tumbleweird.org>',
    //     to: 'adamavenir@hey.com',
    //     subject: `✨ New custom ad type created ${name} (For ${account.org.name})`,
    //     text: `Dimensions: ${width}" wide x ${height}" tall\n\nNotes: ${notes}`,
    //   });
    // }
  };

  return (
    <SignupLayout>
      <form>
        <label htmlFor="name">
          Name of ad type
          <input
            type="text"
            name="name"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </label>
        <label htmlFor="retailPrice">
          Retail price
          <input
            type="number"
            name="retailPrice"
            value={retail_price}
            onChange={e => setRetailPrice(e.target.value)}
          />
        </label>
        <label htmlFor="discountPrice">
          Discount price
          <input
            type="number"
            name="discountPrice"
            value={discount_price}
            onChange={e => setDiscountPrice(e.target.value)}
          />
        </label>
        <label htmlFor="width">
          Width (in inches)
          <input
            type="number"
            name="width"
            value={width}
            onChange={e => setWidth(e.target.value)}
          />
        </label>
        <label htmlFor="height">
          Height (in inches)
          <input
            type="number"
            name="height"
            value={height}
            onChange={e => setHeight(e.target.value)}
          />
        </label>
        {/* <label htmlFor="custom">
          Custom?
          <select
            name="custom"
            value={custom}
            onChange={e => setCustom(e.target.value)}
          >
            <option value="false">Nope, typical ad price</option>
            <option value="true">Yes, this is unique to this org</option>
          </select>
        </label>
        <label htmlFor="orgType">
          Custom for which org? (Optional)
          <select
            name="org"
            value={org}
            onChange={e => setOrg(e.target.value)}
            disabled={!custom}
          >
            <option value="">—</option>
            <OrgOptions />
          </select>
        </label> */}
        <label htmlFor="notes">
          Notes (optional)
          <input
            type="text"
            name="notes"
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
        </label>
        <button type="button" disabled={adTypeState.fetching} onClick={submit}>
          Do the stuff
        </button>
      </form>
    </SignupLayout>
  );
};

export default withAuthenticationRequired(withRouter(CreateAdTypeForm));
