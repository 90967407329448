import gql from 'graphql-tag';

const ADS_BY_ORG = gql`
  query($id: ID!) {
    findOrgByID(id: $id) {
      _id
      name
      orders {
        data {
          _id
          date
          archived
          invoiced
          prepaid
          paid
          canceled
          pricepermonth
          type {
            name
            slug
            width
            height
            notes
            custom
          }
          ads {
            data {
              _id
              slug
              rundate
              image
              placed
              paid
              archived
              type {
                name
                width
                height
                notes
                custom
                slug
              }
            }
          }
        }
      }
    }
  }
`;

export default ADS_BY_ORG;
