import dedent from 'dedent';
import sendEmail from '../_functions/send-mail';

const sendWelcomeEmail = state => {
  const { userEmail, orgName } = state;

  const text = dedent`
  Woo! 🎉

  Thanks for signing up for this silly little app we use to manage Tumbleweird ads.

  It‘s actually a pretty big deal to us.

  Don’t hesitate to reach out any time with questions or feedback.

  —Adam and the Tumbleweird team
  `;

  sendEmail({
    from: 'Tumbleweird Adstravaganza <adstravaganza@tumbleweird.org>',
    to: userEmail,
    subject: `✨ Welcome, ${orgName}!`,
    text,
  });
};

export default sendWelcomeEmail;
