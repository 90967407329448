import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { endsWith } from 'lodash';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useMutation, useQuery } from 'urql';
import nextIssueDate from '../../_utils/next-issue-date';
import {
  sendNewOrderNotification,
  sendOrderConfirmationEmail,
} from '../../_email';
import CREATE_ORDER from './create-order-mutation';
import ALL_ADTYPES from '../ad-type/all-ad-types-query';
import { NumberField, SingleCheckBox } from '../_elements';
// import { EmojiSparkles, EmojiDancers, EmojiHorn, EmojiSmackeroo, EmojiPointR } from '../_elements/emoji';
import { EmojiHorn, EmojiSmackeroo, EmojiPointR } from '../_elements/emoji';
import DefaultLayout from '../_layouts/default-layout';
import AdRateOptions from './ad-rate-options';
import Rocket from '../_elements/rocket';
import { LoadingPage } from '../_views';

const CreateOrder = props => {
  const { account, history } = props;
  const { org } = account;
  const { _id: orgRef, name: orgName, discount } = org;

  const [state, setState] = useState({
    selectedType: 2,
    selectedPrice: '',
    adPrepay: false,
    quantity: 6,
    orderTotal: 0,
    orgName,
  });

  const months = state.quantity;

  let price;
  let adtypes;
  let savings;
  let selected;
  let prepay = 1;
  let multiMonth = 1;
  let discountedPrice;
  let preDiscountPrice;
  let potentialSavings;
  let discountedPriceDollars;
  let discountedPricePerMonth;
  let discountedPricePerMonthFormatted;

  // calculate default ad dates

  const rocketSound = new Audio('/sounds/rocket.mp3');
  const hornSound = new Audio('/sounds/horn.mp3');
  rocketSound.volume = 0.01;
  hornSound.volume = 0.01;

  const [allAdTypesResult] = useQuery({ query: ALL_ADTYPES });
  const [orderState, createOrder] = useMutation(CREATE_ORDER);
  const { loading, data } = allAdTypesResult;
  if (loading) return <LoadingPage />;
  if (allAdTypesResult.error) return history.push('/500');

  if (data) {
    adtypes = data.allAdTypes.data;
    selected = adtypes[state.selectedType];
    if (discount) price = selected.discount_price;
    if (!discount) price = selected.retail_price;

    // calculate discounts
    if (state.adPrepay) prepay = 0.9;
    if (months < 3) multiMonth = 1;
    if (months >= 3 && months <= 6) multiMonth = 0.95;
    if (months >= 6 && months <= 9) multiMonth = 0.9;
    if (months >= 9) multiMonth = 0.85;

    // calculate totals
    preDiscountPrice = price * months;
    discountedPrice = Math.round(preDiscountPrice * prepay * multiMonth);
    discountedPriceDollars = Math.round(
      preDiscountPrice * prepay * multiMonth
    ).toLocaleString('en-US', { minimumFractionDigits: 2 });
    savings = (preDiscountPrice - discountedPrice).toLocaleString('en-US', {
      minimumFractionDigits: 2,
    });
    discountedPricePerMonth = Math.round(discountedPrice / months);
    discountedPricePerMonthFormatted = discountedPricePerMonth.toLocaleString(
      'en-US',
      { minimumFractionDigits: 2 }
    );
    potentialSavings = Math.round(
      discountedPrice - discountedPrice * 0.9
    ).toLocaleString('en-US', { minimumFractionDigits: 2 });
  }

  const handleChange = e => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setState({
      ...state,
      [target.name]: value,
    });
    if (target.name === 'adPrepay' && target.checked) {
      hornSound.play();
    }
  };

  const handleSubmit = () => {
    const d = new Date();
    const now = d.toISOString();
    const nextIssue = nextIssueDate(d);
    const firstAdMonth = nextIssue.getMonth();
    const firstAdYear = nextIssue.getFullYear();

    document.querySelector('.rocket').classList.add('shipping');

    const adSet = [];
    for (let step = 0; step < months; step += 1) {
      const newDate = new Date(firstAdYear, firstAdMonth + step, 20);
      adSet.push({
        rundate: newDate,
        type: { connect: selected._id },
        org: { connect: orgRef },
      });
    }

    const variables = {
      date: now,
      prepaid: state.adPrePay,
      months: Number.parseInt(state.quantity, 10),
      pricepermonth: Number.parseInt(discountedPricePerMonth, 10),
      ads: adSet,
      org: orgRef,
      type: selected._id,
    };

    createOrder(variables)
      .then(rocketSound.play())
      .then(order => {
        if (order.data) {
          sendNewOrderNotification(org.name, variables, selected);
          sendOrderConfirmationEmail(
            account.email,
            org.email,
            org.name,
            variables,
            selected,
            order.data.createAdOrder.ads.data[0]._id
          );
          return history.push(
            `/order/${order.data.createAdOrder._id}?woo=true`
          );
        }
        return history.push('/500');
      });
  };

  return (
    <DefaultLayout pageTitle="Order an ad">
      <form className="order-form">
        <h1>
          Choose your own <strong>ad</strong> venture
        </h1>
        <h2>(Get it?)</h2>
        {adtypes ? (
          <AdRateOptions
            value={state.selectedType}
            adtypes={adtypes}
            discount={discount}
            onChange={handleChange}
          />
        ) : (
          ''
        )}
        <div className="rate-inputs">
          <NumberField
            name="quantity"
            label="Months"
            value={state.quantity}
            onChange={handleChange}
          />
          {months > 2 ? (
            <SingleCheckBox
              name="adPrepay"
              label="Prepay and save 10%"
              value={state.adPrepay}
              onChange={handleChange}
            />
          ) : (
            ''
          )}
          <p className="bundle-savings">
            <strong>Bundle months and save.</strong> 5% off for 3+ months, 10%
            off for 6+, 15% for 9+, 20% off for 12.
          </p>
        </div>
        {adtypes ? (
          <div className="order-calc">
            {state.adPrepay ? (
              <>
                <p>
                  You’re ordering a{' '}
                  <span className="type-name">{selected.name}</span> for{' '}
                  <span className="order-months">{months} issues</span> of
                  Tumbleweird, <span className="order-prepaid">prepaid</span>.
                </p>
                <p>
                  Cost:{' '}
                  <span className="order-total">
                    ${discountedPricePerMonthFormatted} per month
                  </span>{' '}
                  <span className="order-monthly">
                    (${discountedPriceDollars} total)
                  </span>{' '}
                </p>
                <p>
                  <span className="order-savings">${savings} savings</span> for
                  multi-month discount and prepayment. <EmojiHorn />
                </p>
              </>
            ) : (
              <>
                <p>
                  You’re ordering {selected.name === 'Eighth' ? 'an ' : 'a '}
                  <span className="type-name">
                    {selected.name}
                    {endsWith(selected.name, 'page') ||
                    endsWith(selected.name, 'one') ||
                    endsWith(selected.name, 'card')
                      ? ' ad'
                      : ' page ad'}
                  </span>{' '}
                  for <span className="order-months">{months} issues</span> of
                  Tumbleweird.
                </p>
                <p>
                  Cost:{' '}
                  <span className="order-total">
                    ${discountedPricePerMonthFormatted} per month
                  </span>
                </p>
                {months > 2 ? (
                  <div className="for-your-consideration">
                    <p>
                      You’d <strong>save ${potentialSavings} more</strong> by
                      prepaying.
                    </p>
                    <span className="yes-okay-fine">
                      <EmojiSmackeroo />
                      &nbsp;&nbsp;
                      <EmojiPointR />
                      &nbsp;
                      <SingleCheckBox
                        name="adPrepay"
                        label="Let’s do it"
                        value={state.adPrepay}
                        onChange={handleChange}
                      />
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        ) : (
          ''
        )}
        <button
          className="withRocket"
          type="button"
          disabled={orderState.fetching}
          onClick={handleSubmit}
        >
          <span className="rocket icon">
            <Rocket />
          </span>
          Place order
        </button>
      </form>
    </DefaultLayout>
  );
};

export default withAuthenticationRequired(withRouter(CreateOrder));
