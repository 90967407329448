import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const SignupButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    // documented here https://auth0.com/docs/universal-login/new-experience#signup
    <button type="button" onClick={() => loginWithRedirect()}>
      Sign Up
    </button>
  );
};

export default SignupButton;
