import React from 'react';
import DefaultLayout from '../_layouts/default-layout';
import './landing.css';

const Privacy = () => {
  return (
    <DefaultLayout pageTitle="Privacy Policy">
      <div className="greetings">
        <h1>Privacy</h1>
        <h3>Information that is gathered from visitors</h3>
        <p>
          Cookies may be used to remember visitor preferences when interacting
          with the website. Where registration is required, the visitor’s email
          and a username will be stored on the server.
        </p>

        <h3>How the Information is used</h3>
        <p>
          The information is used to enhance the visitor’s experience when using
          the website. E-mail addresses will not be sold, rented or leased to
          3rd parties. E-mail may be sent to inform you of news of our services
          or offers.
        </p>

        <h3>Visitor Options</h3>
        <p>
          If you have subscribed to one of our services, you may unsubscribe by
          sending an email to ads@tumbleweird.org
        </p>

        <p>
          You may be able to block cookies via your browser settings but this
          may prevent you from access to certain features of the website.
        </p>

        <h3>Cookies</h3>
        <p>
          Cookies are small digital signature files that are stored by your web
          browser that allow your preferences to be recorded when visiting the
          website. Also they may be used to track your return visits to the
          website.
        </p>
      </div>
    </DefaultLayout>
  );
};

export default Privacy;
