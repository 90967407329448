import gql from 'graphql-tag';
import USER_SHALLOW from '../user/user-shallow-fragment';
import ORG_SHALLOW from '../org/org-shallow-fragment';

const UPDATE_ACCOUNT = gql`
  mutation($userId: ID!, $user: UserInput!, $orgId: ID!, $org: OrgInput!) {
    updateUser(id: $userId, data: $user) {
      ...UserShallow
    }
    updateOrg(id: $orgId, data: $org) {
      ...OrgShallow
    }
  }
  ${USER_SHALLOW}
  ${ORG_SHALLOW}
`;

export default UPDATE_ACCOUNT;
