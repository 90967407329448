import React from 'react';
import { SignupButton } from '../nav';
import DefaultLayout from '../_layouts/default-layout';
import './landing.css';

const Landing = () => {
  return (
    <DefaultLayout pageTitle="Tumbleweird Adstravaganza">
      <div className="greetings">
        <h1>Tumbleweird<br/> Adstravaganza</h1>
        <SignupButton />
        <h2>Advertising in Tumbleweird</h2>
        <p>
          Putting your organization in Tumbleweird is win-win-win. You get your
          message in front of leaders of Tri-Cities community and culture, you
          support people who are doing new and unique things locally, and you 
          help Tumbleweird grow in its contribution to the gap in local
          journalism.
        </p>
        <h2>About this app</h2>
        <p>
          We consider this site our ‘advertising account executive’. (Be kind.
          She’s new.) This site was built by one of our publishers, Adam Avenir,
          in his spare time because details get Adam’s brain tied in knots and
          emails sometimes languish in his inbox.
        </p>
        <h2>Looking for ideas to maximize your impact?</h2>
        <p>
          If you’re looking for ideas for having the greatest impact with your
          ad, we’re always open to kick around ideas for orders of 6 months or
          more. Just <a href="mailto:adam@tumbleweird.org">reach out</a>.
        </p>
      </div>
    </DefaultLayout>
  );
};

export default Landing;
