/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { NavLink } from 'react-router-dom';
import LogoutButton from './logout-button';
import Logo from './logo';
import './nav.css';

const NavUser = () => {
  return (
    <nav>
      <Logo />
      <NavLink exact={true} to="/">
        Dashboard
      </NavLink>
      <NavLink to="/order">Order ad</NavLink>
      <NavLink to="/account">Account</NavLink>
      <LogoutButton />
    </nav>
  );
};

export default NavUser;
