import gql from 'graphql-tag';
import { ORG_SHALLOW } from '../org';
import { USER_SHALLOW } from '../user';

const UPDATE_ORG = gql`
  mutation($id: ID!, $name: String!, $slug: String!, $email: String!) {
    updateOrg(
      id: $id
      data: { id: $id, name: $name, slug: $slug, email: $email }
    ) {
      ...OrgShallow
      user {
        ...UserShallow
      }
    }
  }
  ${ORG_SHALLOW}
  ${USER_SHALLOW}
`;

export default UPDATE_ORG;
