import React from 'react';

const EmojiSparkles = () => {
  return (
    <span role="img" aria-label="magic sparkles">
      ✨
    </span>
  );
};

const EmojiDancers = () => {
  return (
    <span role="img" aria-label="dancing men">
      👯‍♂️
    </span>
  );
};

const EmojiHorn = () => {
  return (
    <span role="img" aria-label="celebration horn">
      🎉
    </span>
  );
};

const EmojiSmackeroo = () => {
  return (
    <span role="img" aria-label="smackeroo">
      😘
    </span>
  );
};

const EmojiPointR = () => {
  return (
    <span role="img" aria-label="finger pointing right">
      👉
    </span>
  );
};

export { EmojiSparkles, EmojiDancers, EmojiHorn, EmojiSmackeroo, EmojiPointR };
