import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useMutation } from 'urql';
import _ from 'lodash';
import { TextField, SelectField } from '../_elements';
import { sendNewSignupNotification, sendWelcomeEmail } from '../../_email';
import SignupLayout from '../_layouts/signup-layout';
import SIGNUP from './signup-mutation';

const SignupForm = props => {
  const { account, history } = props;
  const { name, email, _id: userRef } = account;

  const [state, setState] = useState({
    userEmail: email,
    userName: name,
    orgName: `${name}'s Org`,
    orgType: '',
    orgDiscount: 'false',
    orgEmail: email,
  });

  const [signupState, signupUser] = useMutation(SIGNUP);

  // determine whether someone pays full price
  let discount = false;
  if (state.orgType !== 'Political' && state.orgDiscount === 'true')
    discount = true;
  if (state.orgType === 'Nonprofit' || state.orgType === 'Education')
    discount = true;

  const handleChange = e => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setState({
      ...state,
      [target.name]: value,
    });
  };

  const variables = {
    id: userRef,
    name: state.userName,
    email: state.userEmail,
    slug: _.kebabCase(state.userName),
    onboarded: true,
    org: {
      name: state.orgName,
      slug: _.kebabCase(state.orgName),
      type: state.orgType,
      discount,
      email: state.orgEmail,
    },
  };

  const handleSubmit = () => {
    signupUser(variables).then(result => {
      if (result.error) return history.push('/500');
      sendNewSignupNotification(state);
      sendWelcomeEmail(state);
      return history.push('/');
    });
  };

  return (
    <SignupLayout>
      <form className="signup-form">
        <TextField
          name="userName"
          label="Name"
          value={state.userName}
          onChange={handleChange}
          required
        />
        <TextField
          name="userEmail"
          label="Email"
          value={state.userEmail}
          onChange={handleChange}
          required
        />
        <TextField
          name="orgName"
          label="Company/organization name"
          value={state.orgName}
          onChange={handleChange}
          required
        />
        <TextField
          name="orgEmail"
          label="What email should we send invoices to?"
          onChange={handleChange}
          value={state.orgEmail}
          required
        />
        <SelectField
          name="orgType"
          label="Type of organization"
          value={state.orgType}
          onChange={handleChange}
          options={[
            'Food & drink',
            'Arts & entertainment',
            'Retail',
            'Event',
            'Political',
            'Education',
            'Government agency',
            'Nonprofit',
            'Other',
          ]}
        />
        <div className="radio-button-set">
          <h6>
            Does your organization make under $1 million per year in revenue?
          </h6>
          <p className="form-hint">
            (We have discounted rates for nonprofits and our community’s
            smallest locally-owned businesses. Political ads are ineligible for
            discounts.)
          </p>
          <label>
            <input
              type="radio"
              name="orgDiscount"
              value="false"
              checked={state.orgDiscount === 'false'}
              onChange={handleChange}
            />
            No
          </label>
          <label>
            <input
              type="radio"
              name="orgDiscount"
              value="true"
              checked={state.orgDiscount === 'true'}
              onChange={handleChange}
            />
            Yes
          </label>
        </div>
        <button
          type="button"
          disabled={signupState.fetching}
          onClick={handleSubmit}
        >
          Let the games* begin
        </button>
        <p className="no-games">
          * <strong>Editor’s note:</strong> No games. This is just a boring
          website. <em>Mostly.</em>
          <br /> ...Or <strong>is it???</strong>
        </p>
      </form>
    </SignupLayout>
  );
};

export default withAuthenticationRequired(withRouter(SignupForm));
