import React, { useState } from 'react';
import { useQuery } from 'urql';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AD_BY_ID from './ad-by-id-query';
import DefaultLayout from '../_layouts/default-layout';
import Upload from '../upload/upload';
import { LoadingPage } from '../_views';
import './ad.css';
import { EmojiSparkles, EmojiHorn } from '../_elements/emoji';
import sendAdUpdateEmail from '../../_email/send-ad-update-email';
import sendAdUpdateNotification from '../../_email/send-ad-update-notification';

const ViewAd = props => {
  const { history, account } = props;
  const { adid } = props.match.params;

  const [state, setState] = useState({
    url: `https://app.tumbleweird.org/ads/${adid}`,
    copied: false,
    uploaded: false,
    ad: '',
  });

  const uploadSound = new Audio('/sounds/upload.mp3');
  uploadSound.volume = 0.3;
  const copiedSound = new Audio('/sounds/copied.mp3');
  copiedSound.volume = 0.3;

  const handleImageAdded = () => {
    setState({
      ...state,
      uploaded: true,
    });
    sendAdUpdateNotification(state.ad);
    if (!account) {
      sendAdUpdateEmail(state.ad);
    }
    try {
      uploadSound.play();
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleCopy = () => {
    setState({
      ...state,
      copied: true,
    });
    copiedSound.play();
  };

  const [result] = useQuery({
    query: AD_BY_ID,
    variables: { id: adid },
    pause: !adid,
  });

  const { fetching, error, data } = result;

  while (fetching) return <LoadingPage />;
  if (error) console.error(error);

  if (data) {
    const ad = data.findAdByID;
    state.ad = ad;
    let rundate = false;
    let orderdate = false;
    if (ad.rundate) {
      rundate = new Date(ad.rundate);
      rundate = rundate.toLocaleString('en-US', {
        month: 'long',
        year: 'numeric',
      });
    }
    if (ad.order.date) {
      orderdate = new Date(ad.order.date);
      orderdate = orderdate.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });
    }

    return (
      <DefaultLayout pageTitle={`Ad for ${ad.order.org.name}`}>
        <div className="view-item">
          <div className="info-wrapper">
            <div className="info-container">
              <h2>
                {rundate} {ad.order.org.name} ad
              </h2>
              <p>
                Dimensions: {ad.type.width}&rdquo; wide x {ad.type.height}
                &rdquo; tall
              </p>
              <p>Ordered {orderdate}</p>
              {ad.type.notes ? <p>{ad.type.notes}</p> : ''}
              {/* <p className="magic-link-url">{state.url}</p> */}
            </div>
            <div className="magic-link-container">
              <CopyToClipboard text={state.url} onCopy={handleCopy}>
                {!state.copied ? (
                  <button type="button" className="magic-link-button">
                    Copy <EmojiSparkles />
                    magic link <EmojiSparkles />
                  </button>
                ) : (
                  <button type="button" className="magic-link-button">
                    <EmojiSparkles />
                    Copied! <EmojiSparkles />
                  </button>
                )}
              </CopyToClipboard>
              <p className="magic-link-info">
                (Your designer can use this link to
                <br /> upload your ad without a login.)
              </p>
            </div>
          </div>
          {state.uploaded ? (
            <div className="you-did-it">
              <h2>
                You did it! <EmojiHorn />
              </h2>
              <p>
                That’s all you have to do.
                <br />
                We’ll take it from here.
                <Link to="/">Go back to the dashboard</Link>
              </p>
            </div>
          ) : (
            ''
          )}
          {/* ad.placed ad.paid ad.archived */}
          <Upload adid={adid} ad={ad} onChange={handleImageAdded} />
        </div>
      </DefaultLayout>
    );
  }

  return history.push('/500');
};

export default ViewAd;
