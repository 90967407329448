import React from 'react';
import { Helmet } from 'react-helmet-async';

export default props => {
  const { pageTitle, children } = props;
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="chrome-1" />
        <meta name="description" content="Tumbleweird ad manager" />
        <meta name="keywords" content="" />
        <meta name="author" content="Tumbleweird, SPC" />
        <title>
          {pageTitle ? `${pageTitle} — ` : ''}
          Tumbleweird Adstravaganza
        </title>
        <link rel="shortcut icon" href="" />
        <body className="signup-page" />
      </Helmet>
      <main>{children}</main>
    </>
  );
};
