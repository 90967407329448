import React from 'react';
import DefaultLayout from '../_layouts/default-layout';
import CreateAdTypeForm from './create-ad-type-form';

const CreateAdType = ({ account }) => {
  return (
    <DefaultLayout pageTitle="Create ad type">
      <h1>Create Ad Type</h1>
      <div id="create-ad-type">
        <CreateAdTypeForm account={account} />;
      </div>
    </DefaultLayout>
  );
};

export default CreateAdType;
