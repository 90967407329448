import gql from 'graphql-tag';
import AD_SHALLOW from '../ad/ad-shallow-fragment';

const ADD_IMAGES = gql`
  mutation($id: ID!, $ad: AdInput!) {
    updateAd(id: $id, data: $ad) {
      ...AdShallow
    }
  }
  ${AD_SHALLOW}
`;

export default ADD_IMAGES;
