import gql from 'graphql-tag';

const ADTYPE_SHALLOW = gql`
  fragment AdTypeShallow on AdType {
    _id
    slug
    name
    retail_price
    discount_price
    width
    height
    notes
    custom
  }
`;

export default ADTYPE_SHALLOW;
