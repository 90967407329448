const nextIssueDate = date => {
  let adMonth;
  let adYear;

  const month = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();

  // get first ad month

  // before the 11th
  // in all months but December,
  // use next month for issue day
  if (day < 11 && month < 11) {
    console.log('before the 11th in all months but December');
    adMonth = month + 1;
    adYear = year;
  }

  // after the 10th
  // in all months but Dec/Nov
  // use month after next for issue day
  if (day > 10 && month < 10) {
    console.log('after the 10th in all months but Dec/Nov');
    adMonth = month + 2;
    adYear = year;
  }

  // In Nov, after Nov 10
  // use January for the issue day
  // (January issue prints Dec 20)
  if (day > 11 && month === 10) {
    console.log('In Nov, after Nov 10');
    adMonth = 0;
    adYear = year + 1;
  }

  // In Dec, before Dec 11
  // use January for the issue day
  // (January issue prints Dec 20)
  if (day < 11 && month === 11) {
    console.log('In Dec, before Dec 11');
    adMonth = 0;
    adYear = year + 1;
  }

  // In Dec, after Dec 10
  // use February for the issue day
  // (February issue prints Jan 20)
  if (day > 10 && month === 11) {
    console.log('In Dec, after Dec 10');
    adMonth = 1;
    adYear = year + 1;
  }

  const newDate = new Date(adYear, adMonth, 20);
  return newDate;
};

export default nextIssueDate;
