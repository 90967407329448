import gql from 'graphql-tag';
import ORG_DEEP from './org-deep-fragment';

const ALL_ORGS = gql`
  query {
    allOrgs {
      data {
        ...OrgDeep
      }
    }
  }
  ${ORG_DEEP}
`;

export default ALL_ORGS;
