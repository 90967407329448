import React from 'react';
import { useQuery } from 'urql';
import queryString from 'query-string';
import ORDER_BY_ID from './order-by-id-query';
import DefaultLayout from '../_layouts/default-layout';
import OrderListItem from './order-list-item';
import { LoadingPage } from '../_views';
import './order.css';

const ViewOrder = props => {
  const { history, match } = props;
  const { orderid } = match.params;
  const { woo } = queryString.parse(window.location.search);

  const [result] = useQuery({
    query: ORDER_BY_ID,
    variables: { id: orderid },
    pause: !orderid,
  });

  const { fetching, data, error } = result;
  while (fetching) return <LoadingPage />;

  if (data) {
    const { findAdOrderByID: order } = data;

    return (
      <DefaultLayout pageTitle={`Order for ${order.org.name}`}>
        <div className={order.canceled ? 'order canceled' : 'order'}>
          {woo ? (
            <div className="woo">
              <h1>
                <span role="img" aria-label="woo">
                  🎉
                </span>{' '}
                Order successfully placed!
              </h1>
              <p>
                Thank you so much. You are a central part of helping Tumbleweird
                invest in the community and culture of the Tri-Cities.
              </p>
            </div>
          ) : (
            ''
          )}
          <OrderListItem order={order} showprice="true" />
        </div>
      </DefaultLayout>
    );
  }
  if (error) console.error(error);
  return history.push('/500');
};

export default ViewOrder;

// "data": {
//   "findAdOrderByID": {
//     "_id": "275260169477161484",
//     "slug": null,
//     "date": "2020-08-30T06:03:23.584Z",
//     "org": {
//       "_id": "274855983875883533",
//       "name": "&yet",
//       "discount": true,
//       "__typename": "Org",
//       "slug": "yet",
//       "type": "Other",
//       "email": "andy@andyet.com"
//     },
//     "type": {
//       "name": "Back page",
//       "slug": "back-page",
//       "__typename": "AdType",
//       "_id": "274490265045041672",
//       "retail_price": 2750,
//       "discount_price": 2750,
//       "width": "9.25",
//       "height": "13.75",
//       "notes": "0.4-inch bleed on all sides",
//       "custom": false
//     },
//     "months": 3,
//     "pricepermonth": 2351,
//     "prepaid": null,
//     "archived": null,
//     "invoiced": null,
//     "canceled": null,
//     "paid": null,
//     "ads": {
