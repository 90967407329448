import React from 'react';

const TextField = props => {
  const { name, label, value, required } = props;
  function handleChange(e) {
    props.onChange(e);
  }
  return (
    <label>
      {label}
      <input name={name} type="text" onChange={handleChange} value={value} required={required} />
    </label>
  );
};

export default TextField;
