import React from 'react';
import { Link } from 'react-router-dom';
import './ad.css';

const AdListItem = props => {
  const { ad } = props;
  const { type } = ad;
  const url = `/ads/${ad._id}`;
  const typeImage = `/images/${type.slug}.png`;

  let rundate = '';

  if (ad && ad.rundate) {
    rundate = new Date(ad.rundate);
    rundate = rundate.toLocaleString('en-US', {
      month: 'short',
      year: 'numeric',
    });
  }

  return (
    <div className="purchased-item">
      <Link to={url}>
        {rundate ? <h6>{rundate}</h6> : ''}
        {ad.image ? (
          <img src={ad.image} alt="ad" />
        ) : (
          <img src={typeImage} alt={type.name} />
        )}
        {/* ad.placed ad.paid ad.archived */}
      </Link>
    </div>
  );
};

export default AdListItem;
