import React from 'react';
import DefaultLayout from '../_layouts/default-layout';
import { Users } from '../user';
import { Orgs } from '../org';
import { AdTypes } from '../ad-type';

const AdminDashboard = ({ account }) => {
  return (
    <DefaultLayout>
      <h1>Dashboard</h1>
      {/* { user < /> */}
      <pre>
        <code>{JSON.stringify(account, undefined, 2)}</code>
      </pre>
      <hr />
      <Orgs />
      <Users />
      <AdTypes />
    </DefaultLayout>
  );
};

export default AdminDashboard;
