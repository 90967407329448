import gql from 'graphql-tag';
import ORDER_SHALLOW from './order-shallow-fragment';

const ALL_ORDERS = gql`
  query {
    allAdOrders {
      data {
        ...OrderShallow
      }
    }
  }
  ${ORDER_SHALLOW}
`;

export default ALL_ORDERS;
