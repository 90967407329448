import React from 'react';

const NumberField = props => {
  const { name, label, value } = props;
  function handleChange(e) {
    props.onChange(e);
  }
  return (
    <label>
      {label}
      <input
        name={name}
        type="number"
        min="1"
        max="12"
        onChange={handleChange}
        value={value}
      />
    </label>
  );
};

export default NumberField;
