import gql from 'graphql-tag';
import ORG_SHALLOW from './org-shallow-fragment';
import USER_SHALLOW from '../user/user-shallow-fragment';
import AD_SHALLOW from '../ad/ad-shallow-fragment';
import ADTYPE_SHALLOW from '../ad-type/ad-type-shallow-fragment';

const ORG_DEEP = gql`
  fragment OrgDeep on Org {
    ...OrgShallow
    user {
      ...UserShallow
    }
    ads {
      data {
        ...AdShallow
      }
    }
    custom_adtypes {
      data {
        ...AdTypeShallow
      }
    }
  }
  ${ORG_SHALLOW}
  ${USER_SHALLOW}
  ${AD_SHALLOW}
  ${ADTYPE_SHALLOW}
`;

export default ORG_DEEP;
