import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';
import DefaultLayout from '../_layouts/default-layout';
import ADS_BY_ORG from './ads-by-org-query';
import { OrderListItem } from '../order';
import { LoadingPage } from '../_views';

const OrgAds = props => {
  const { history, account } = props;
  const { org } = account;

  const [result] = useQuery({
    query: ADS_BY_ORG,
    variables: { id: org._id },
    pause: !org,
  });

  const { data, err, fetching } = result;
  if (err) history.push('/500');
  if (fetching) return <LoadingPage />;
  const { findOrgByID } = data;
  const { orders } = findOrgByID;

  if (orders) {
    return (
      <DefaultLayout>
        <h1>Tumbleweird Adstravaganza</h1>
        {orders.data.map(order => {
          return (
            <div key={order._id} className="order-group">
              <OrderListItem order={order} />;
            </div>
          );
        })}
      </DefaultLayout>
    );
  }
  return (
    <DefaultLayout>
      <h1>Tumbleweird Adstravaganza</h1>
      <p>
        You don’t have any ads yet. You can{' '}
        <Link to="/order">check out our rates or order one here</Link>.
      </p>
    </DefaultLayout>
  );
};

export default OrgAds;
