import React from 'react';
import { useQuery } from 'urql';
import DefaultLayout from '../_layouts/default-layout';
import ADS_BY_ORG from '../ad/ads-by-org-query';
import OrderList from '../order/order-list';
import OrderProcessInfo from './order-process-info';
import { LoadingPage } from '../_views';
import './user-dashboard.css';

const UserDashboard = props => {
  const { history, account } = props;
  const { org } = account;

  const [result] = useQuery({
    query: ADS_BY_ORG,
    variables: { id: org._id },
    pause: !org,
  });

  const { data, err, fetching } = result;
  if (err) history.push('/500');
  if (fetching) return <LoadingPage />;
  const { findOrgByID } = data;
  const { orders } = findOrgByID;

  if (orders.data.length > 0) {
    return (
      <DefaultLayout pageTitle={`${org.name} Adstravagnza`}>
        <div className="user-dashboard">
          <h1>{org.name} Adstravaganza</h1>
          <p className="order-helptext">
            <strong>Click an ad below</strong> to upload an image or
            <br />
            to get a magic link to share with your designer.
          </p>
          <OrderList orders={orders} showprice="false" />
          <OrderProcessInfo />
        </div>
      </DefaultLayout>
    );
  }
  return (
    <DefaultLayout>
      <div className="user-dashboard welcome">
        <h1>{org.name} Adstravaganza</h1>
        <p>
          You don’t have any active ads—<em>yet</em>.
        </p>
        <OrderProcessInfo history={history} />
      </div>
    </DefaultLayout>
  );
};

export default UserDashboard;
