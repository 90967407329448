import gql from 'graphql-tag';

const AD_SHALLOW = gql`
  fragment AdShallow on Ad {
    _id
    slug
    rundate
    image
    thumb
    tiny
    placed
    paid
    archived
    type {
      name
      slug
      width
      height
      notes
    }
  }
`;

export default AD_SHALLOW;
