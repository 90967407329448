import React from 'react';
import { useQuery } from 'urql';
import ALL_ORDERS from './all-orders-query';
import DefaultLayout from '../_layouts/default-layout';
import OrderListItem from './order-list-item';
import { LoadingPage } from '../_views';

const ListOrders = props => {
  const { history } = props;

  const [result] = useQuery({
    query: ALL_ORDERS,
  });

  const { fetching, data } = result;
  if (fetching) return <LoadingPage />;
  if (data) {
    return (
      <DefaultLayout pageTitle="Orders">
        <h1>Orders</h1>
        {data.allAdOrders.data.map(order => {
          return <OrderListItem key={order._id} order={order} />;
        })}
      </DefaultLayout>
    );
  }
  return history.push('/500');
};

export default ListOrders;
