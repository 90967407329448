import React from 'react';
import AdRate from './ad-rate';

const adRateOptions = props => {
  const { value, adtypes, discount } = props;

  function handleChange(e) {
    props.onChange(e);
  }

  return (
    <div className="rate-options">
      {adtypes.map((adtype, index) => (
        <label className="rate-item" key={adtype._id} htmlFor={`type-${index}`}>
          <input
            type="radio"
            name="selectedType"
            id={`type-${index}`}
            value={index}
            // TODO this feels hacky as hell
            checked={Number.parseInt(value, 10) === index}
            onChange={handleChange}
          />
          <AdRate adtype={adtype} discount={discount} />
        </label>
      ))}
    </div>
  );
};

export default adRateOptions;
